<template>
    <div class="card-body w-100" v-if="sales == null">
      <div class="text-center text-info my-2">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </div>
    <div class="card-body py-5 w-100 text-center" v-else-if="sales.length <= 0">
      <h5 class="mb-3">Nenhum item encontrado!</h5>
      <img width="250" src="@/assets/images/not-data.svg" />
    </div>
    <div class="table-responsive mb-0" v-else>
      <table class="table table-centered table-nowrap">
        <thead class="thead-light text-left">
          <tr>
            <th>ID</th>
            <th v-if="tipo == 0">Cliente</th>
            <th>Data</th>
            <th>Total</th>
            <th>Pagamento</th>
            <th>Entrega</th>
            <th>Método de Pagamento</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody class="text-left">
          <tr v-for="(data, index) in sales" :key="data.id">
            <td class="text-body font-weight-bold">
              {{ data.id }}
            </td>
            <td v-if="tipo == 0"><a href="" @click.prevent="$router.push({ name: 'detalhe-cliente', params:{id: data.customer_id} })" class="cursor-pointer">{{ data.customer_name }}</a></td>
            <td>{{ data.created_at | moment('DD/MM/YYYY')}}</td>
            <td>{{ data.price_total | currency('R$ ')}}</td>
            <td>
              <span
                class="badge badge-pill badge-soft-success font-size-12"
                :class="{
                  'badge-soft-warning': `${data.payment_status}` == 0,
                  'badge-soft-info': `${data.payment_status}` == 1,
                  'badge-soft-danger': `${data.payment_status}` == 2,
                  'badge-soft-success': `${data.payment_status}` == 3,
                }"
                >{{ data.payment_status == 0 ? "Pendente" : data.payment_status == 1 ? "Processando" : data.payment_status == 2 ? "Cancelado" : data.payment_status == 3 ? "Pago" : "Pendente"}}</span
              >
            </td>
            <td>
              <span
                class="badge badge-pill badge-soft-success font-size-12"
                :class="{
                  'badge-soft-warning': `${data.shipping_status}` == 0,
                  'badge-soft-info': `${data.shipping_status}` == 1,
                  'badge-soft-info': `${data.shipping_status}` == 2,
                  'badge-soft-success': `${data.shipping_status}` == 3,
                }"
                >{{ data.shipping_status == 0 ? "Pendente" : data.shipping_status == 1 ? "Preparando para envio" : data.shipping_status == 2 ? "Enviado" : data.shipping_status == 3 ? "Entregue" : "Pendente"}}</span>
            </td>
            <td>
              {{ data.payment_method }}
            </td>
            <td>
              <b-button v-b-tooltip.hover title="Visualizar Rápida do Pedido" class="btn btn-success btn-sm btn-rounded" @click="previewSale(data)">
                <i class="fas fa-eye"></i>
              </b-button>
              <b-button v-b-tooltip.hover title="Visualizar Pedido" @click.prevent="$router.push({name: 'detalhes-da-venda', params: {id: data.id}})" class="btn btn-info btn-sm btn-rounded ml-2">
                <i class="fas fa-receipt"></i>
              </b-button>
              <a class="text-warning ml-4" href="" v-b-tooltip.hover title="Editar Pedido" @click.prevent="$router.push({name: 'venda', params: {id: data.id}})">
                <i class="fas fa-edit font-size-10"></i>
              </a>
              <a @click.prevent="$emit('remover', index, data)" class="text-danger ml-4" v-b-tooltip.hover title="Remover Pedido" href="#">
                <i class="fas fa-trash font-size-10"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <b-modal v-model="showModal" title="Venda" centered v-if="sale != null">
        <p class="mb-2">
          Venda id:
          <span class="text-primary">{{sale.id}}</span>
        </p>
        <p class="mb-4">
          Cliente:
          <span class="text-primary"><a href="" @click.prevent="$router.push({ name: 'cliente', params:{id: sale.customer_id} })" >{{sale.customer_name}}</a></span>
        </p>
        <div class="table-responsive">
          <table class="table table-centered table-nowrap">
            <thead>
              <tr>
                <th scope="col">Produto</th>
                <th scope="col">Nome</th>
                <th class="text-right" scope="col">Preço</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="product in sale.product" :key="product.id">
                <th scope="row" v-if="product.image != null">
                  <div v-for="img in product.image" :key="img.id">
                    <img
                      v-if="img.capa == 1"
                      :src="img.image300"
                      alt
                      class="avatar-sm"
                    />
                  </div>
                </th>
                <th scope="row" v-else>
                    <img
                      src="@/assets/images/notpro.jpg"
                      alt
                      class="avatar-sm"
                    />
                </th>
                <td>
                  <div>
                    <h5 class="text-truncate font-size-14">
                     {{product.name}}
                    </h5>
                    <p class="text-muted mb-0">{{product.price | currency('R$ ')}} x {{product.amount}}</p>
                    <p v-if="product.options && product.options != null && product.options != ''">
                      <span class="w-100 d-block mt-3" v-for="(product, index) in JSON.parse(product.options)" :key="index">
                        {{index}}: <span class="border p-1 ml-1" v-for="(p, i) in product" :key="i">{{p.name}}</span>
                      </span>
                    </p>
                  </div>
                </td>
                <td class="text-right">{{product.price * product.amount | currency('R$ ')}}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <h6 class="m-0 text-right">Sub Total:</h6>
                </td>
                <td class="text-right">{{sale.price_subtotal | currency('R$ ')}}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <h6 class="m-0 text-right">Frete:</h6>
                </td>
                <td class="text-right">{{sale.shipping_type == "Grátis" ? "Grátis" : sale.shipping_price | currency('R$ ')}}</td>
              </tr>
              <tr v-if="sale.juros > 0">
                <td colspan="2">
                  <h6 class="m-0 text-right">Juros:</h6>
                </td>
                <td class="text-right">{{sale.juros | currency('R$ ')}}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <h6 class="m-0 text-right">Total:</h6>
                </td>
                <td class="text-right">{{sale.price_total | currency('R$ ')}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <template v-slot:modal-footer>
          <b-button variant="secondary" @click="showModal = false"
            >Fechar</b-button
          >
        </template>
      </b-modal>
    </div>
</template>
<script>
export default {
  components: {},
  props: {
    sales: {
      type: Array,
      default: function () {
        return null;
      },
    },
    tipo: {
      type: Number,
      default:0
    },
  },
  data() {
    return {
      showModal: false,
      sale: null
    };
  },
    methods: {
    previewSale(data){
        this.showModal = true
        this.sale = data
    }
  },
};
</script>