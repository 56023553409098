<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div
          class="page-title-box d-flex align-items-center justify-content-between"
        >
          <h4 class="mb-0 font-size-18">Vendas</h4>

          <!--<div class="page-title-right">
            <b-button
              variant="success"
              @click.prevent="$router.push({ name: 'venda' })"
            >
              <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
              Adicionar
            </b-button>
          </div>-->
        </div>
      </div>
    </div>
    <div class="row text-left">
      <div class="col-12">
        <div class="card">
          <div
            class="card-header bg-white border-bottom d-flex align-items-center justify-content-between"
          >
            <h5 class="card-title">
              <i class="fas fa-filter text-dark mr-2"></i>
              Filtro
            </h5>
            <b-button variant="light" @click.prevent="limparFiltro()" v-b-tooltip.hover title="Limpar Filtro">
              <i class="bx bx-sync font-size-16 align-middle"></i>
            </b-button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12 col-md-12 col-lg-8 mb-3">
                <label>Cliente</label>
                <multiselect
                  v-model="customer"
                  :options="customers"
                  class="helo"
                  placeholder="Cliente"
                  label="name"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :loading="isLoadingCustomer"
                  @search-change="searchCustomer"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mb-3">
                <label>Entrega</label>
                <multiselect
                  v-model="status_frete"
                  :options="options_frete_status"
                  class="helo"
                  label="text"
                  placeholder="Status de Entrega"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-2 mt-lg-0 mb-3">
                <label>Pagamento</label>
                <multiselect
                  v-model="status_pagamento"
                  :options="options_pagamento_status"
                  class="helo"
                  placeholder="Status de Pagamento"
                  select-label="Selecionar"
                  label="text"
                  deselect-label="Remover"
                  :allow-empty="false"
                ></multiselect>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-2 mt-lg-0 mb-3">
                <label>Data Início</label>
                <b-form-datepicker
                  placeholder="Data de início"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="date_start"
                ></b-form-datepicker>
              </div>
              <div class="col-sm-12 col-md-12 col-lg-4 mt-2 mt-lg-0 mb-3">
                <label>Data Final</label>
                <b-form-datepicker
                  placeholder="Data de final"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  v-model="date_end"
                ></b-form-datepicker>
              </div>
            </div>
            <div class="row" v-if="loading">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-else-if="error">
              <div class="col-12">
                <div class="card text-center">
                  <div class="card-body text-center">
                    <div class="text-center text-danger my-2">
                      <h5 class="mb-3">Erro ao carregar dados!</h5>
                      <img width="250" src="@/assets/images/error-img.png" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-else-if="sales.length <= 0">
              <div class="col-12">
                <div class="card text-center">
                  <div class="card-body py-5">
                    <h5 class="mb-3">Nenhum item encontrado!</h5>
                    <img width="250" src="@/assets/images/not-data.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5" v-else>
              <div class="col-12">
                <Sales @remover="removerItem" :sales="sales" />
              </div>
            </div>
             <div class="row py-5" v-if="loadingbtn && sales.length > 0">
                <div class="col-12 text-center">
                  <b-button variant="info" @click.prevent="carregarMais()">
                    <i class="bx bx-plus font-size-16 align-middle mr-2"></i>
                    Carregar Mais
                  </b-button>
                </div>
              </div>
            <infinite-loading @infinite="infiniteHandler">
              <div slot="spinner" class="text-info"></div>
              <div slot="no-more"></div>
              <div slot="no-results"></div>
            </infinite-loading>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import DatePicker from 'vue2-datepicker'
import Sales from '@/components/widgets/sales'
import { mapActions } from 'vuex'
import InfiniteLoading from 'vue-infinite-loading'
export default {
  components: {
    Multiselect,
    DatePicker,
    Sales,
    InfiniteLoading,
  },
  data() {
    return {
      daterange: null,
      name: null,
      status_frete: null,
      status_pagamento: null,
      options_frete_status: [
        { value: 0, text: 'Pendente' },
        { value: 1, text: 'Preparando para envio' },
        { value: 2, text: 'Enviado' },
        { value: 3, text: 'Entregue' },
      ],
      options_pagamento_status: [
        { value: 0, text: 'Pendente' },
        { value: 1, text: 'Processando' },
        { value: 2, text: 'Cancelado' },
        { value: 3, text: 'Pago' },
      ],
      sales: [],
      filtro: {
        limit: 10,
        page: 1,
      },
      filtroCustomer: {
        limit: 10,
      },
      customers: [],
      isLoadingCustomer: true,
      customer: null,
      loading: true,
      error: false,
      date_start: null,
      date_end: null,
      loadingbtn: true,
    }
  },
  watch: {
    customer: function () {
      if (this.customer != null) {
        this.filtro.customer_id = this.customer.id
        this.listSales()
      }
    },
    status_frete: function () {
      if (this.status_frete != null) {
        this.filtro.shipping_status = this.status_frete.value
        this.listSales()
      }
    },
    status_pagamento: function () {
      if (this.status_pagamento != null) {
        this.filtro.payment_status = this.status_pagamento.value
        this.listSales()
      }
    },
    date_start: function () {
      if (this.date_start != null) {
        this.filtro.date_start = this.date_start
        this.listSales()
      }
    },
    date_end: function () {
      if (this.date_end != null) {
        this.filtro.date_end = this.date_end
        this.listSales()
      }
    },
  },
  mounted() {
    this.listCustomers()
  },
  methods: {
    ...mapActions('sale', ['ActionSaleAll', 'ActionSaleUpdate']),
    ...mapActions('customer', ['ActionCustomerAll']),
    async carregarMais() {
      try {
        this.loadingbtn = true
        await this.ActionSaleAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.sales.push(...res.data.data.list)
          } else {
            this.loadingbtn = false
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.sales = []
        this.loading = false
        this.error = true
      }
    },
    async infiniteHandler($state) {
      try {
        this.loadingbtn = true
        await this.ActionSaleAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page += 1
            this.sales.push(...res.data.data.list)
            $state.loaded()
          } else {
            this.loadingbtn = false
            $state.complete()
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        this.sales = []
        $state.complete()
      }
    },
    async listSales() {
      try {
        this.filtro.page = 1
        this.loading = true
        this.loadingbtn = true
        await this.ActionSaleAll(this.filtro).then((res) => {
          if (res.data.data.list.length) {
            this.filtro.page = 2
            this.sales = res.data.data.list
          } else {
            this.loadingbtn = false
            this.sales = []
          }
          this.loading = false
          this.error = false
        })
      } catch (_) {
        this.loading = false
        this.error = true
        this.sales = []
      }
    },
    searchCustomer(e) {
      this.filtroCustomer.search = e
      this.listCustomers()
    },
    async listCustomers() {
      try {
        this.isLoadingCustomer = true
        await this.ActionCustomerAll(this.filtroCustomer).then((res) => {
          this.customers = res.data.data.list
          this.isLoadingCustomer = false
        })
      } catch (_) {
        this.customers = []
        this.isLoadingCustomer = false
      }
    },
    limparFiltro() {
      this.filtro = {
        limit: 10,
        page: 1,
      }
      this.date_start = null
      this.date_end = null
      this.customer = null
      this.status_frete = null
      this.status_pagamento = null
      this.loadingbtn = true
      this.listSales()
    },
    removerItem(index, data) {
      this.$swal({
        icon: 'warning',
        title: 'Confirmação',
        text: 'Deseja Remover Este Item?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `SIM`,
        denyButtonText: `NÃO`,
      }).then((result) => {
        if (result.isConfirmed) {
          this.removerItemUp(index, data)
        }
      })
    },
    async removerItemUp(index, data) {
      try {
        this.$swal({
          title: 'Aguarde!',
          text: 'Removendo item',
          icon: 'info',
          showConfirmButton: false,
        })
        var t = data
        data.trash = 1
        var dados = {
          data: data,
          id: data.id,
        }
        await this.ActionSaleUpdate(dados)
        this.$swal({
          title: 'Sucesso',
          text: 'Ação realizada com sucesso!',
          icon: 'success',
          timer: 2000,
          timerProgressBar: true,
        })
        this.sales.splice(this.sales.indexOf(t), 1)
      } catch (e) {
        this.$swal({
          title: 'Erro!',
          text: e.body.message,
          icon: 'error',
          timer: 2000,
          timerProgressBar: true,
        })
      }
    },
  },
}
</script>
